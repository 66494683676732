@import '../../styles/helpers';

.errorContainer {
  margin: 80px auto;
  width: 416px;
  text-align: center;
}

.errorCode {
  font-size: 72px;
  font-weight: $font-weight-bold;
  line-height: 1;
  color: $primary-color;
}

.errorInfo {
  margin: 16px auto 32px;
  font-size: 20px;
  font-weight: $font-weight-semi-bold;
  color: $base-dark-gray;
}

.errorHelp {
  margin-bottom: 32px;
  font-size: 16px;
  font-weight: $font-weight-semi-bold;
  color: $base-middle-gray;
}

.errorBtn {
  font-size: $font-size-sm;
  font-weight: $font-weight-semi-bold;
}
