@import '../../styles/helpers.scss';

.tableTitle {
  display: flex;
  justify-content: space-between;
  padding: 24px 24px;

  img {
    margin-left: 16px;
  }
}

.transactionTitleBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 13px 24px 13px 16px;
}

.widgetContentBlock {
  margin: 0 24px 24px;
}

.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  padding: 13px 24px 13px 16px;
  border-radius: 8px;
  background-color: $base-light-gray;
}

.image {
  width: 32px;
  height: 32px;
}

.checkboxCol {
  width: 5%;
}

.textAlignRight {
  text-align: right !important;
}

.textAlignLeft {
  text-align: left !important;
}

// .textAlignCenter,
.textAlignCenter th,
.textAlignCenter td {
  text-align: center;
}

.isActiveYes {
  color: $green;
}

.isActiveNo {
  color: $red;
}

.scrollable {
  overflow-x: hidden;
  overflow-y: auto;
}

.scrollable::-webkit-scrollbar {
  width: 0.5rem;
}

.scrollable::-webkit-scrollbar-thumb {
  background-color: $primary-color;
}

.optionsList {
  border: 1px solid $gray-300;
  max-height: 362px;
  width: 100%;
  position: absolute;
  background: #fff;
  z-index: 1;
  border-radius: 8px;
}

.optionsList li {
  padding: 0.5rem;
}

.optionsListActive,
.optionsList li:hover {
  background: $primary-color;
  color: #fff;
  cursor: pointer;
}

.totalBorder {
  border-left: 3px solid $primary-color;
}
