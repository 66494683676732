@import '../../styles/helpers';

.root {
  display: flex;
  flex-direction: column;
  width: $sidebar-width-open;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  padding: 16px;
  background-color: $sidebar-color;
  overflow-y: auto;
  transition: height 1s;
  transform: translateX(-$sidebar-width-open);

  @media print {
    display: none;
  }

  :global(.sidebar-hide) & {
    display: none;
  }

  @media (max-width: breakpoint-max(sm)) {
    position: static !important;
    width: auto;
    margin: 12px 24px 0;
    border-radius: 8px;
    padding: 0;
    height: 0;
    transform: none;

    .logo,
    .sidebarAlerts,
    .sidebarLabels,
    .navTitle {
      display: none;
    }
  }
}

//.sidebarOpen {
//  @media (min-width: breakpoint-min(md)) {
//    //height: auto !important;
//  }
//}

.sidebarOpen {
  @media (max-width: breakpoint-max(sm)) {
    height: 276px;
  }
}

// .logo {
//   display: flex;
//   align-items: center;
//   margin: 16px 0 24px;
//   width: 100%;

//   .title {
//     margin-left: 12px;
//     font-size: 20px;
//     font-weight: $font-weight-bold;
//     text-transform: uppercase;
//   }
// }

.logo {
  display: flex;
  align-items: center;
  margin: 24px 0;
  width: 100%;
  justify-content: center;
}

.nav {
  padding-bottom: 10px;
  overflow-y: auto;
  overflow-x: hidden;

  li > a > span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
  }
}

.navTitle {
  margin: 32px 0 8px;
  font-size: $font-size-sm;
  font-weight: $font-weight-normal;
  color: $base-dark-gray;
  text-transform: uppercase;
}
